//export const url_bd = " http://localhost:57781/api";
//export const url_bd = "http://knockit.api.propertyrent.cl/api"; //QA
export const url_bd = "https://api.knockit.rent/api"; //PROD

export const url_bd_back = "https://api.back.knockit.rent/api";

export const url_ggccdebts = "/GGCCDebts/Get";
//HGZT
export const url_eecc_hgzt = "/HgztEstadoCuenta/Get";
export const url_arr_hgzt = "/HgztArriendoCalculado/Get";
//https://api.back.knockit.rent/api/HgztArriendoCalculado/Get

export const url_tasacion = "/TasApp/Insert";
export const url_Servipag = "/Servipag/Get";
export const url_Vouchers = "/DebtRut/Get";
export const url_Payment_temp = "/PaymentTemp/Get";

export const url_Property_Service_Company = "/PropertyServiceCompany/Get";
export const url_property_address = "/PropertyAddress/Get";

export const url_activate_user = "/UserActivate/Get";
export const url_grabar_temporal = "/FormSolicitudTemporal/Get";
export const url_datos_personal = "/DatoPersonal/Get";
export const url_all_request = "/RequestAll/Get";
export const url_grabar_documentos = "/DocumentAws/Insert";
export const url_email_reserva = "/EmailReserva/Get";
export const url_olvidar_password = "/OlvidarPassword/Get";
export const url_cambiar_password = "/CambiarPassword/Get";
export const url_datos_personal_udp = "/DatoPersonal/Update";
export const url_solicitud_lessor = "/SolicitudLessor/Insert";
export const url_propiedad = "/MisPropiedades/Get";
export const url_email_contact = "/EmailContact/Get";
export const url_user_register = "/UserRegistration/Insert";
export const url_user_access = "/Login/Get";
export const url_dsh_propiedades = "/DashboardPropiedad/Get";
export const url_change_rol = "/UserRol/Update";
export const url_dsh_recaudacion = "/DashboardRecaudacion/Get";
export const url_dsh_ingreso_egreso = "/DashboardIngresoEgreso/Get";
export const url_contract_generate = "/ContractGenerate/Get";
export const url_Typology = "/Typologies/Get";
export const url_contract_generate_prestacion =
  "/ContractGeneratePrestacion/Get";
export const url_contract_generate_anexo_prestacion =
  "/ContractGenerateAnexoPrestacion/Get";
export const url_contract_properties_user = "/ContractPropertiesUser/Get";
export const url_menu = "/Menu/Get";
export const url_solicitud = "/Solicitud/InsertSolicitud";
export const url_listado_movement = "/ListadoMovement/Get";
export const url_listado_properties = "/ListadoPropertiesUser/Get";
export const url_ultimos_arriendos = "/PropertyFinder/GetUltimosArriendos";
export const url_arriendos_destacados =
  "/PropertyFinder/GetArriendosDestacados";
export const url_DshOperacional = "/DashboardOperacional/GetOperacional";
export const url_tipo_inmueble = "/PropertyFinder/GetTiposInmuebles";
export const url_detalle_propiedad = "/PropertyFinder/GetDetallesPropiedad";
export const url_buscador_propiedad = "/BuscadorPropiedad/GetBuscadorPropiedad";

export const url_property_commune = "/PropertyFinder/GetComunas";
export const url_caracteristica_propiedad = "/PropertyFinder/GetPropiedad";

export const url_users = "/Users/GetAll";
export const url_insert_user = "/Users/InsertUser";
export const url_delete_user = "/Users/DeleteUser";
export const url_update_user = "/Users/UpdateUser";
export const url_authentication = "/Authentication/Get";

export const url_insert_accoutingtemplate = "/AccountingTemplate/Insert";
export const url_AccountingTemplate = "/AccountingTemplate/Get";

export const url_ContractRut = "/ContractRut/Get";

export const url_PropertiesContract = "/PropertieContract/GetProperties";

export const url_persons = "/Persons/GetPerson";
export const url_insert_person = "/Persons/InsertPersonUser";
export const url_delete_person = "/Persons/DeletePerson";
export const url_update_person = "/Persons/UpdatePerson";

export const url_roles = "/Rol/GetAll";

export const url_investors = "/Investor/GetInvestors";
export const url_insert_investor = "/Investor/InsertInvestor";
export const url_delete_investor = "/Investor/DeleteInvestor";
export const url_update_investor = "/Investor/UpdateInvestor";

export const url_PropertieMemo = "/PropertieMemo/GetPropertiesMemo";

export const url_AccountPlan = "/AccountPlan/Get";

export const url_AccountStatesInvestor = "/AccountStatesInvestor/Get";
export const url_AccountingSeatTemplate = "/AccountingSeatTemplate/Get";

export const url_lessors = "/Lessor/GetLessor";
export const url_insert_lessor = "/Lessor/InsertLessor";
export const url_delete_lessor = "/Lessor/DeleteLessor";
export const url_update_lessor = "/Lessor/UpdateLessor";

export const url_Movement_Property = "/MovementProperty/Get";
export const url_insert_Movement_Property = "/MovementProperty/Insert";
export const url_delete_Movement_Property = "/MovementProperty/Delete";
export const url_update_Movement_Property = "/MovementProperty/Update";

export const url_Movement_Property_User = "/MovementPropertyUser/Get";

export const url_projects = "/Project/GetProject";
export const url_insert_project = "/Project/InsertProject";
export const url_delete_project = "/Project/DeleteProject";
export const url_update_project = "/Project/UpdateProject";

export const url_properties = "/Propertie/GetProperties";
export const url_PropertiesParameter = "/PropertieParameter/Get";
export const url_insert_propertie = "/Propertie/InsertPropertie";
export const url_delete_propertie = "/Propertie/DeletePropertie";
export const url_update_propertie = "/Propertie/UpdatePropertie";

export const url_complements = "/Complement/Get";
export const url_insert_complement = "/Complement/Insert";
export const url_delete_complement = "/Complement/Delete";
export const url_update_complement = "/Complement/Update";

export const url_endorsements = "/Endorsement/Get";
export const url_insert_endorsement = "/Endorsement/Insert";
export const url_delete_endorsement = "/Endorsement/Delete";
export const url_update_endorsement = "/Endorsement/Update";

export const url_propertyOwners = "/PropertyOwner/Get";
export const url_insert_propertyOwners = "/PropertyOwner/Insert";
export const url_delete_propertyOwners = "/PropertyOwner/Delete";
export const url_update_propertyOwners = "/PropertyOwner/Update";

export const url_prospectus = "/Prospectus/Get";
export const url_insert_prospect = "/Prospectus/Insert";
export const url_delete_prospect = "/Prospectus/Delete";
export const url_update_prospect = "/Prospectus/Update";

export const url_typologies = "/Typologies/Get";
export const url_insert_typology = "/Typologies/Insert";
export const url_delete_typology = "/Typologies/Delete";
export const url_update_typology = "/Typologies/Update";

export const url_typologiesType = "/TypologiesType/Get";
export const url_insert_typologyType = "/TypologiesType/Insert";
export const url_delete_typologyType = "/TypologiesType/Delete";
export const url_update_typologyType = "/TypologiesType/Update";

export const url_featuresServices = "/FeaturesServices/Get";
export const url_insert_featuresServices = "/FeaturesServices/Insert";
export const url_delete_featuresServices = "/FeaturesServices/Delete";
export const url_update_featuresServices = "/FeaturesServices/Update";

export const url_featuresServicesType = "/FeaturesServicesType/Get";
export const url_insert_featuresServicesType = "/FeaturesServicesType/Insert";
export const url_delete_featuresServicesType = "/FeaturesServicesType/Delete";
export const url_update_featuresServicesType = "/FeaturesServicesType/Update";

export const url_region = "/Region/Get";
export const url_insert_region = "/Region/Insert";
export const url_delete_region = "/Region/Delete";
export const url_update_region = "/Region/Update";

export const url_additionalUnit = "/AdditionalUnit/Get";
export const url_insert_additionalUnit = "/AdditionalUnit/Insert";
export const url_delete_additionalUnit = "/AdditionalUnit/Delete";
export const url_update_additionalUnit = "/AdditionalUnit/Update";

export const url_city = "/City/Get";
export const url_insert_city = "/City/Insert";
export const url_delete_city = "/City/Delete";
export const url_update_city = "/City/Update";

export const url_bank = "/Bank/Get";
export const url_insert_bank = "/Bank/InsertBank";
export const url_delete_bank = "/Bank/DeleteBank";
export const url_update_bank = "/Bank/UpdateBank";

export const url_PropertyServiceCompany = "/PropertyServiceCompany/Get";
export const url_insert_PropertyServiceCompany =
  "/PropertyServiceCompany/Insert";
export const url_delete_PropertyServiceCompany =
  "/PropertyServiceCompany/Delete";
export const url_update_PropertyServiceCompany =
  "/PropertyServiceCompany/Update";

export const url_ServiceCompany = "/ServiceCompany/Get";
export const url_insert_ServiceCompany = "/ServiceCompany/Insert";
export const url_delete_ServiceCompany = "/ServiceCompany/Delete";
export const url_update_ServiceCompany = "/ServiceCompany/Update";

export const url_AccountPerson = "/AccountPerson/Get";
export const url_insert_AccountPerson = "/AccountPerson/InsertAccountPerson";
export const url_delete_AccountPerson = "/AccountPerson/DeleteAccountPerson";
export const url_update_AccountPerson = "/AccountPerson/UpdateAccountPerson";

export const url_AccountStates = "/AccountStates/Get";
export const url_insert_AccountStates = "/AccountStates/Insert";
export const url_insert_masive_AccountStates = "/AccountStatesMasive/Insert";
export const url_delete_AccountStates = "/AccountStates/Delete";
export const url_update_AccountStates = "/AccountStates/Update";

export const url_AccountType = "/AccountType/Get";
export const url_insert_AccountType = "/AccountType/Insert";
export const url_delete_AccountType = "/AccountType/Delete";
export const url_update_AccountType = "/AccountType/Update";

export const url_AccountT = "/AccountT/Get";
export const url_AccountingSeat = "/AccountingSeat/Get";

export const url_MovementParameter = "/MovementParameter/Get";

export const url_Contracts = "/Contracts/Get";
export const url_insert_Contracts = "/Contracts/Insert";
export const url_delete_Contracts = "/Contracts/Delete";
export const url_update_Contracts = "/Contracts/Update";

export const url_Documents = "/Document/Get";
export const url_insert_Documents = "/Document/Insert";
export const url_delete_Documents = "/Document/Delete";
export const url_update_Documents = "/Document/Update";

export const url_ProjectMemo = "/ProjectMemo/Get";
export const url_insert_ProjectMemo = "/ProjectMemo/Insert";
export const url_delete_ProjectMemo = "/ProjectMemo/Delete";
export const url_update_ProjectMemo = "/ProjectMemo/Update";

export const url_PropertiesStatus = "/PropertiesStatus/Get";
export const url_insert_PropertiesStatus = "/PropertiesStatus/Insert";
export const url_delete_PropertiesStatus = "/PropertiesStatus/Delete";
export const url_update_PropertiesStatus = "/PropertiesStatus/Update";

export const url_states = "/States/Get";

export const url_TypeMoney = "/TypeMoney/Get";
export const url_insert_TypeMoney = "/TypeMoney/Insert";
export const url_delete_TypeMoney = "/TypeMoney/Delete";
export const url_update_TypeMoney = "/TypeMoney/Update";

export const url_TypeServiceCompany = "/TypeServiceCompany/Get";
