import { ActionTypes } from "../../constants/actionTypes";

// ** Initial State
const initialState = {
  VouchersList: null,
  SelectedVouchers: [],
};

export const vouchersListReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_VOUCHERSLIST:
      return { ...state, VouchersList: payload };
    case ActionTypes.SELECTED_VOUCHERS:
      return { ...state, SelectedVouchers: payload };
    default:
      return state;
  }
};

// export const selectedVouchersReducer = (state = {}, { type, payload }) => {
//   switch (type) {
//     case ActionTypes.SELECTED_VOUCHERS:
//       return { ...state, ...payload };
//     default:
//       return state;
//   }
// };
