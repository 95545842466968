import {
  url_bd,
  url_datos_personal,
  url_propiedad,
  url_user_register,
  url_user_access,
  url_dsh_propiedades,
  url_change_rol,
  url_dsh_recaudacion,
  url_dsh_ingreso_egreso,
  url_contract_generate,
  url_solicitud_lessor,
  url_solicitud,
  url_menu,
  url_ultimos_arriendos,
  url_arriendos_destacados,
  url_property_commune,
  url_tipo_inmueble,
  url_detalle_propiedad,
  url_buscador_propiedad,
  url_caracteristica_propiedad,
  url_tasacion,
  url_Movement_Property_User,
  url_contract_properties_user,
  url_listado_properties,
  url_DshOperacional,
  url_listado_movement,
  url_bank,
  url_AccountType,
  url_datos_personal_udp,
  url_olvidar_password,
  url_cambiar_password,
  url_activate_user,
  url_grabar_temporal,
  url_contract_generate_prestacion,
  url_contract_generate_anexo_prestacion,
  url_email_contact,
  url_email_reserva,
  url_all_request,
  url_grabar_documentos,
  url_Typology,
  url_Property_Service_Company,
  url_property_address,
  url_Vouchers,
  url_Servipag,
  url_Payment_temp,
  url_arr_hgzt,
  url_eecc_hgzt,
  url_bd_back,
  url_investors,
  url_ggccdebts,
} from "../../../src/configs/apiUrl";

const getUrl = (comp, bd) => {
  let url_comp = "";

  switch (comp) {
    case "GGCCDebts":
      url_comp = `${url_ggccdebts}`;
      break;
    case "Investors":
      url_comp = `${url_investors}`;
      break;
    case "HGZT_EECC":
      url_comp = url_eecc_hgzt;
      break;
    case "HGZT_ArrHist":
      url_comp = url_arr_hgzt;
      break;
    case "DatosPersonales":
      url_comp = url_datos_personal;
      break;
    case "Servipag":
      url_comp = url_Servipag;
      break;
    case "PaymentTemp":
      url_comp = url_Payment_temp;
      break;
    case "Vouchers":
      url_comp = url_Vouchers;
      break;
    case "PropertyAddress":
      url_comp = url_property_address;
      break;
    case "GrabarDocumentos":
      url_comp = url_grabar_documentos;
      break;
    case "GetTypolgy":
      url_comp = url_Typology;
      break;
    case "AllRequest":
      url_comp = url_all_request;
      break;
    case "EmailContact":
      url_comp = url_email_contact;
      break;
    case "EmailReserva":
      url_comp = url_email_reserva;
      break;
    case "GrabarTemporal":
      url_comp = url_grabar_temporal;
      break;
    case "ActivateUser":
      url_comp = url_activate_user;
      break;
    case "OlvidarPassword":
      url_comp = url_olvidar_password;
      break;
    case "CambiarPassword":
      url_comp = url_cambiar_password;
      break;
    case "UpdateDatosPersonales":
      url_comp = url_datos_personal_udp;
      break;
    case "AccountType":
      url_comp = url_AccountType;
      break;
    case "CaracteristicaPropiedad":
      url_comp = url_caracteristica_propiedad;
      break;
    case "ListadoMovement":
      url_comp = url_listado_movement;
      break;
    case "ListadoProperties":
      url_comp = url_listado_properties;
      break;
    case "DshOperacional":
      url_comp = url_DshOperacional;
      break;
    case "Bank":
      url_comp = url_bank;
      break;
    case "PropertyServiceCompany":
      url_comp = url_Property_Service_Company;
      break;
    case "ContractPropertiesUser":
      url_comp = url_contract_properties_user;
      break;
    case "TipoInmueble":
      url_comp = url_tipo_inmueble;
      break;
    case "BuscadorPropiedad":
      url_comp = url_buscador_propiedad;
      break;
    case "DetallePropiedad":
      url_comp = url_detalle_propiedad;
      break;
    case "Property_Comunne":
      url_comp = url_property_commune;
      break;
    case "UltimosArriendos":
      url_comp = url_ultimos_arriendos;
      break;
    case "ArriendosDestacados":
      url_comp = url_arriendos_destacados;
      break;
    case "SolicitudLessor":
      url_comp = url_solicitud_lessor;
      break;
    case "Solicitud":
      url_comp = url_solicitud;
      break;

    case "Propiedades":
      url_comp = url_propiedad;
      break;
    case "Tasacion":
      url_comp = url_tasacion;
      break;
    case "Register":
      url_comp = url_user_register;
      break;
    case "Access":
      url_comp = url_user_access;
      break;
    case "DshPropiedades":
      url_comp = url_dsh_propiedades;
      break;
    case "DshRecaudacion":
      url_comp = url_dsh_recaudacion;
      break;
    case "DshIngresoEgreso":
      url_comp = url_dsh_ingreso_egreso;
      break;
    case "Menu":
      url_comp = url_menu;
      break;
    case "ChangeRol":
      url_comp = url_change_rol;
      break;
    case "ContractGenerate":
      url_comp = url_contract_generate;
      break;
    case "ContractGeneratePrestacion":
      url_comp = url_contract_generate_prestacion;
      break;
    case "ContractGenerateAnexoPrestacion":
      url_comp = url_contract_generate_anexo_prestacion;
      break;
    case "MovementPropertyUser":
      url_comp = url_Movement_Property_User;
      break;
    default:
      break;
  }

  switch (bd) {
    case "back":
      return `${url_bd_back}${url_comp}`;

    case "front":
      return `${url_bd}${url_comp}`;

    default:
      return `${url_bd}${url_comp}`;
  }
};

export default getUrl;
