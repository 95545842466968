// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
// import { vouchersListReducer, selectedVouchersReducer } from "./vouchers";
import { vouchersListReducer } from "./vouchers";
import { paymentRut } from "./payhere";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  vouchersListReducer,
  paymentRut,
});

export default rootReducer;
